<template scoped>
  <Panel-CRUD
    ref="crud"
    :guid="guid"
    :endpoint="endpoint"
    :toPage="toPage"
    :resourceServer="resourceServer"
    :resourceId="resourceId"
    title="Template"
    :create="create"
    :resourceServerApi="false"
    apiPath="Backend/Template"
  >
    <template>
      <el-table-column prop="name" label="Name" width="360"> </el-table-column>
      <el-table-column prop="enable" label="Live Mode">
        <template slot-scope="scope">
          <el-switch
            @change="setEnable(scope.row)"
            v-model="scope.row.enable"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="action" label="Action">
        <template slot-scope="scope">
          <el-row>
            <el-col :span="24">
              <el-tooltip
                class="item"
                effect="dark"
                content="Edit"
                placement="top"
              >
                <el-button
                  @click="edit(scope.row)"
                  icon="el-icon-edit"
                  circle
                ></el-button>
              </el-tooltip>
            </el-col>
            <el-col :span="24" :style="{ marginTop: '10px' }">
              <el-tooltip
                class="item"
                effect="dark"
                content="Copy Id"
                placement="top"
              >
                <el-button
                  @click="copy(scope.row)"
                  icon="el-icon-document-copy"
                  circle
                ></el-button>
              </el-tooltip>
            </el-col>
            <el-col :span="24" :style="{ marginTop: '10px' }">
              <el-tooltip
                class="item"
                effect="dark"
                content="Delete"
                placement="top"
              >
                <el-button
                  type="danger"
                  @click="remove(scope.row)"
                  icon="el-icon-delete"
                  circle
                ></el-button>
              </el-tooltip>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </template>
  </Panel-CRUD>
</template>

<script>
import copy from "copy-to-clipboard";

export default {
  props: {
    guid: String,
    endpoint: String,
    toPage: Function,
    resourceServer: String,
    resourceId: String,
  },
  components: {
    "Panel-CRUD": () => import("cms_infrastructure_web/src/sdk/Admin/CRUD/CRUD-List.vue"),
  },
  methods: {
    setEnable: function (item) {
      this.$refs.crud.setEnable(item);
    },
    create: function () {
      this.toPage(
        "components/Template/Portal-Template-CreateUpdate.vue"
      );
    },
    edit: function (item) {
      this.toPage(
        "components/Template/Portal-Template-CreateUpdate.vue",
        {
          templateId: item.id,
        }
      );
    },
    copy: function (item) {
      copy(item.id);
    },
    remove: function (item) {
      this.$refs.crud.remove(item);
    },
  },
};
</script>